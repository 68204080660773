.header-div {

    width: 100%;
    height: 110px;
    flex-shrink: 0;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    position: relative;
    z-index: 9999;
    
  /* box-shadow: 0 1px 4px 1px rgba(0,0,0,.2); */
}

.main-header {
    display: flex;
    /* width: 65%; */
    align-items: center;
    gap: 12%;
}

.logo-header{
    width: 190px;
    height: 70px;

}

.address-bar{
    display: flex;
    /* width: 65%;
    justify-content: flex-end; */
    align-items: center;
    gap: 39px;
    flex-shrink: 0;
}

.address-bars{
    width: auto;
    height: 40px;
    display: flex;
    flex-shrink: 0;
}

.address-icon {
    width: 40px;
    height: 40px;
    flex-shrink: 0;

}

.address-text {
    height: 40px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
}

.address-text .ptx{
    color: #000;
font-size: 12px;
font-style: normal;
font-weight: 200;
padding: 0 0 0 10px;
margin: 0;
line-height: 135.023%; /* 18.903px */
}