.navbar {
    background-color: #fffffff1;
    position:fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 9999;
    box-sizing: border-box;
    box-shadow: 0 1px 4px 1px rgba(0,0,0,.2);
    border:none;
  }
  
  .relative-nav {
    position: relative;
  }
  
  .fixed-nav {
    position: fixed;
    background-color: #ffffff;
  }